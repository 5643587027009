<template>
  <div class="agrement_acontent">
    <p class="title">书营AI写作用户协议</p>
    <div class="container_precondition">
      <p class="main">
        欢迎加入书营AI写作会员大家庭！感谢您选择成为我们的尊贵会员。作为会员，您将享受到我们提供的一系列高级功能、独家特权和个性化服务。
      </p>
      <p class="main">
        在使用书营AI写作产品及服务(以下简称“本产品及服务”)之前，您（以下简称“您”或用户）应当阅读并遵守《书营AI写作用户协议》（以下简称“本协议”），<b>
          请您务必审慎阅读，充分理解各条款内容，特别是免除或者限制责任的条款、争议解决和法律适用条款</b
        >（粗体标注内容）。
      </p>
      <p class="main">
        当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，或您按照激活页面提示填写信息、阅读并同意本协议且完成全部激活程序后，或您以其他本产品允许的方式实际使用本产品及服务时，即表示您已充分阅读、理解并接受本协议的全部内容，本协议即产生法律约束力。<b
          >您承诺接受并遵守本协议的约定，届时您不应以未阅读本协议的内容或者未获得我们对您问询的解答等理由，主张本协议无效，或要求撤销本协议。</b
        >
      </p>
      <p class="main">
        请您注意，<b
          >我们会不时检查并更新用户协议，因此有关用户协议的内容会随之变化。</b
        >
        我们将书面通知您定期查看用户协议页面，以确保您了解用户协议的最新版本。如果您在阅读完最新版用户协议后继续本产品和服务，即视为您已充分理解并同意我们对用户协议的更新修改。
      </p>
    </div>

    <div class="container_main">
      <p class="main_title">一、缔约主体</p>
      <p class="main">
        本协议由您与<b style="color: red">浙江艺阁文化传媒集团有限公司</b
        >及所有关联公司
        (以下简称“我们”“本公司”）共同缔结，本协议对您与我们均有合同效力。
      </p>
      <p class="main_title">二、协议内容和效力</p>
      <p class="main">
        本协议内容包括本协议正文及所有本产品已经发布或将来可能发布的隐私权政策、各项政策、规则声明、通知、警示、提示、说明(以下简称“规则”)。前述规则为本协议不可分割的组成部分，与本协议具有同等法律效力。
      </p>
      <p class="main_title">三、服务内容</p>
      <p class="main">
        用户需要通过登录或注册使用到书营AI写作软件或服务，可以使用书营AI写作提供的AI写作等功能，如小红书文案、知乎问答、抖音文案、短视频脚本、等完整的服务。这些功能服务可能根据您需求的变化，随着因服务版本不同、或服务提供方的单方判断而被优化或修改，或因定期、不定期的维护而暂缓提供。<b
          >我们有权自行决定对服务或服务任何部分及其相关功能、应用软件进行变更、升级、修改、转移，并在相关页面上公示通知。
        </b>
      </p>
      <p class="main_title">四、注册及帐号使用</p>
      <p class="main">
        4.1.<b>您确认，在您完成注册程序或以其他本产品允许的方式实际使用服务时，您应当是具备完全民事权利能力和与所从事的民事行为相适应的行为能力的自然人、法人或其他组织。</b>若您不具备前述主体资格，请勿使用服务，否则您及您的监护人应承担因此而导致的一切后果，且我公司有权注销(永久冻结)您的账户，并向您及您的监护人索偿。<b
          >如您代表一家企业、组织机构或其他法律主体进行注册或以其本公司允许的方式实际使用本服务，则您声明和保证，您已经获得充分授权并有权代表该公司、组织机构或法律主体注册使用本产品，并受本协议约束。
        </b>
      </p>
      <p class="main">
        4.2.您可以使用您提供或确认的微信号或手机号或者本产品允许的其它方式作为帐号进行注册，您注册后可提供简单的能够反映您身份的信息，同时系统将为您自动分配一个帐号，<b
          >此帐号是您帐号的唯一识别标记，注册后无法修改。您理解并同意，您承诺注册的帐号名称、头像和昵称等信息中不得出现违法和不良信息，不得冒充他人，不得未经许可为他人注册，不得以可能导致其他用户误认的方式注册帐号或设置帐号，不得使用可能侵犯他人权益的用户名(包括但不限于涉嫌商标权、名誉权侵权等)，否则本公司有权不予注册或停止服务并收回帐号，因此产生的损失由您自行承担。</b
        >
      </p>
      <p class="main">
        4.3.您了解并同意，<b>注册帐号所有权归属于本公司，注册完成后，您仅获得帐号使用权。帐号使用权仅归属于初始申请注册人，不得以任何方式转让或被提供予他人使用，</b>否则，我公司有权立即不经通知收回该帐号，由此带来的因您使用本服务产生的全部数据、信息等被清空、丢失等的损失，您应自行承担。
      </p>
      <p class="main">
        4.4.在您成功注册后，本产品将根据您的身份要素识别您的身份和授权登录。<b>“身份要素”包括但不限于您的账户名称、密码、短信校验码、手机号码、微信号码。您同意基于不同的终端以及您的使用习惯本产品可能采取不同的验证措施识别您的身份。</b>例如您的账户在新设备首次登录时，我们可能通过密码加短信校验码的方式确认您的身份。请您务必妥善保管您的终端及账户、密码/身份要素信息，并对利用该账户和密码/身份要素信息所进行的一切活动(包括但不限于网上点击同意或提交各类规则协议或购买服务、分享资讯或图片、电话回拨等)负全部责任。<b>您承诺，在密码或账户遭到未获授权的使用，或者发生其他任何安全问题时，将立即通知我们，且您同意并确认，除法律另有规定外，我公司不对上述情形产生的任何直接或间接的遗失或损害承担责任。</b>除非有法律规定或司法裁定，且征得我公司的同意，否则，您的账户、密码不得以任何方式转让、赠予或继承(与账户相关的财产权益除外)。
      </p>
      <p class="main">
        4.5.<b>您了解并同意，您不得通过以下任何方式为自已或他人开通本产品： </b>
      </p>
      <div class="main">
        <li>以营利、经营等非个人使用的目的为自己或他人开通本产品；</li>
        <li>
          通过任何机器人软件、蜘蛛软件、爬虫软件、刷屏软件等任何程序、软件方式为自己或他人开通本产品；
        </li>
        <li>
          通过任何不正当手段或以违反诚实信用原则的方式为自己或他人开通本产品；
        </li>
        <li>通过非正常渠道指定的方式为自己或他人开通本产品；</li>
        <li>通过侵犯或他人合法权益的方式为自己或他人开通本产品；</li>
        <li>
          通过其他违反相关法律、行政法规、国家政策等的方式为自己或他人开通本产品。
        </li>
      </div>
      <p class="main">
        4.6.<b
          >您了解并同意，如您注册帐号后连续超过12个月未登录，我们为优化管理等目的，有权回收该帐号。</b
        >
      </p>
      <p class="main">
        4.7.<b
          >如您注销本产品账户或停止使用服务时，本公司有权自行对帐号相关内容及信息以包括但不限于删除等方式进行处理，且无需就此向用户承担任何责任。</b
        >
      </p>
      <p class="main">
        4.8.您应维持密码及帐号的机密安全，不得将帐号转让、出借、出租或售卖。<b>如果用户未保管好自己的帐号和密码而对用户、本公司或第三方造成伤害，用户将负全部责任。</b>用户同意若发现任何非法适用用户帐号或安全漏洞的情况，有义务立即通知本公司。
      </p>
      <p class="main">
        4.9.若用户登录、使用帐号头像、个人简介等帐号信息资料存在和不良信息，本公司有权采取通知限期改正、暂停使用等措施。
      </p>
      <p class="main_title">四、注册及帐号使用</p>
      <p class="main">5.1.您充分了解并同意以下服务费用与使用授权的内容：</p>
      <p class="main">5.1.1. 限时免费</p>
      <p class="main">免费时段：书营AI写作会不定期开启限时免费活动。</p>
      <p class="main">
        免费时段的通知方式：用户可以在官网首页以及相关功能上看到限免的广告或标签。
      </p>
      <p class="main">
        <b
          >本服务的任何免费试用或免费功能和服务不应视为本公司放弃后续收费的权利。本公司有权在产品官网上以公告的形式通知您收费标准。若您继续使用本服务，则需按本公司公布的收费标准支付相应费用。</b
        >
      </p>
      <p class="main">5.1.2. 付费使用</p>
      <p class="main">服务对象：用户</p>
      <p class="main">服务内容：详见本协议第三条。</p>
      <p class="main">收费方式：第三方支付。</p>
      <p class="main">
        所有费用需通过本公司接受的支付方式进行支付。前述使用费不包含其它任何税款、费用或相关汇款等支出，否则您应补足付款或自行支付该费用。<b>本公司有权根据实际情况单方调整费用标准及收费方式，并以公告形式通知您，而无需获得您的事先同意，如您不同意收费应当立即停止服务的使用，否则使用则视为您已同意并应当缴纳费用。</b>您应自行承担因使用本产品服务所产生的费用，包括不限于上网流量费、通信服务费、功能使用费等。
      </p>
      <p class="main">5.1.3会员套餐升级及退费</p>
      <p class="main">
        <b
          >会员一经充值不予退还,低版本升级为高版本时间将自动叠加，优先消耗高版本的天数，高版本到期后再转为低版本。</b
        >
      </p>
      <p class="main">5.2 特别要求</p>
      <p class="main">
        5.2.1.利用本公司提供的服务进行的经营活动要获得国家有关部门的许可批准的，您应获得该有关的许可或批准；
      </p>
      <p class="main">
        5.2.2.不利用本公司提供的资源和服务上传(Upload)下载(download)
        储存、发布如下信息或者内容，不为他人发布该等信息提供任何便利：
      </p>
      <div class="main">
        <li>违反国家规定的政治宣传和/或新闻信息；</li>
        <li>涉及国家秘密和/或安全的信息；</li>
        <li>封建迷信和/或淫秽、色情、下流的信息或教唆犯罪的信息；</li>
        <li>博彩有奖、赌博游戏、“私服”“外挂”等非法互联网出版活动；</li>
        <li>违反国家民族和宗教政策的信息；</li>
        <li>妨碍互联网运行安全的信息;</li>
        <li>
          侵害他人合法权益的信息和/或其他有损干社会秩序，社会治安，公共道德的信息或内容；
        </li>
        <li>
          冒充任何人或机构，或以虚伪不实的方式陈述或谎称与任何人或机构有关；
        </li>
        <li>
          其他违反中国或其您所在国国家管辖法所适用法律法规、部门规章或国家政策的内容。
        </li>
      </div>
      <p class="main">
        <b
          >因本服务并非为某些特定目的而设计，您不可将本服务用于包括但不限于核设施、军事用途、医疗设施、交通通讯等重要领域。如果因为软件或服务的原因导致上述操作失败而带来的人员伤亡、财产损失和环境破坏等，本公司不承担法律责任。</b
        >
      </p>
      <p class="main">
        5.2.3.<b
          >不利用本公司提供的服务从事损害本公司及其关联公司，以及书营AI相关第三方的合法权益之行为，前述损害关联公司合法权益的行为包括但不限于损害商誉，违反本公司及其关联公司，以及书营AI写作相关第三方公布的任何服务协议/条款、管理规范、交易规则等规范内容、破坏或试图破坏本公司及其关联公司，以及书营AI写作相关第三方公平交易环境或正常交易秩序等。</b
        >
      </p>
      <p class="main">
        5.2.4.<b>不利用本公司提供的资源和服务进行或允许第三方进行如下活动:</b>
      </p>
      <div class="main">
        <li>
          未经本公司许可，以非法手段窃取、泄露、转让、滥用、篡改或破坏本公司的数据和/或信息，包括但不限于TTS数据、个人信息、生物识别数据等；
        </li>
        <li>
          未经本公司许可，使用上述合法或非法获知的数据和/或信息，以直接或间接的方式创建、训练或改进相似或存在竞争的产品和服务；
        </li>
        <li>其他非法克隆TTS数据、不正当竞争、知识产权侵权等行为；</li>
        <li>其他非法克隆TTS数据、不正当竞争、知识产权侵权等行为；</li>
        <li>
          
          您充分了解并同意，本产品仅为用户提供本协议规定的服务内容，无法代替用户进行信息判断和行为决策。相应地，您应了解，使用本产品服务可能发生来自他人非法或不当行为(或信息)的风险，您应自行判断及行动，并自行承担相应的风险；
        </li>
      </div>
      <p class="main">5.2.5.<b>您承诺不对本产品和服务从事以下行为:</b></p>
      <div class="main">
        <li>
          将有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序之资料，加以上载、张贴、发送电子邮件或以其他方式传送；
        </li>
        <li>
          干扰或破坏本服务或与本服务相连接之服务器和网络，或违反任何关于本服务连接网络之规定、程序、政策或规范；
        </li>
        <li>
          通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；
        </li>
        <li>
          通过非本产品开发、授权的第三方软件、插件、外挂、系统，登录或使用软件及服务，或制作、发布、传播上述工具；
        </li>
        <li>
          自行、授权他人或利用第三方软件对本软件及其组件、模块、数据等进行干扰。
        </li>
      </div>
      <p class="main">
        5.2.6.<b>您承诺，使用本产品服务时您将严格遵守本协议。</b>
      </p>
      <p class="main">
        5.2.7.您同意并接受本产品无须时时监控您上载、传送的资料及信息，但我们有权对您使用服务的情况进行审查、监督并采取相应行动，包括但不限于删除信息、中止或终止服务，及向有关机关报告。
      </p>
      <p class="main">
        5.2.8.您承诺不以任何形式使用本服务侵犯本公司的商业利益，或从事任何可能对本公司造成损害或不利于本公司的行为。
      </p>
      <p class="main">
        5.2.9.<b
          >您了解并同意，在本产品服务提供过程中，本公司及其关联公司自行或由其他第三方合作伙伴向您发送广告、推广或宣传信息(包括商业或非商业信息)。本公司依照法律的规定对广告商履行相关义务(包括向您提供选择关闭广告信息的功能)，您应当自行判断广告信息的真实性和可靠性并为自己的判断负责，除法律明确规定外，您因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，本公司不承担责任。</b
        >
      </p>
      <p class="main">
        5.2.10.您充分了解并同意，您必须为自己注册帐号下的一切行为负责。<b
          >您应对使用本服务时接触到的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。本公司无法且不会对您因前述风险而导致的任何损失或损害承担责任。</b
        >
      </p>
      <p class="main">
        5.2.11.如果本公司发现或收到他人举报您有违反本协议约定的条款，本公司有权不经通知随时对相关内容进行删除、屏蔽，并采取包括但不限于收回帐号，限制、暂停、终止您使用部分或全部本服务，追究法律责任等措施。<b
          >如因您的行为给本公司或关联公司造成损失的，您应予全额赔偿。
        </b>
      </p>
      <p class="main_title">六、知识产权</p>
      <p class="main">
        6.1.您了解及同意，除非本公司另行声明，本协议项下服务包含的所有产品、技术、软件、程序、数据及其他信息(包括但不限于文字、图像、图片、照片、音频、视频、图表、色彩、版面设计、电子文档）的所有知识产权(包括但不限于版权、商标权、专利权、商业秘密等)及相关权利均归本公司或其关联公司或第三方权利人所有。<b
          >除本公司或第三方权利人明示同意外，您无权复制、传播、转让、许可或提供他人使用上述资源，否则应承担相应的责任。</b
        >
      </p>
      <p class="main">
        6.2.您应保证，您不得(并不得允许任何第三人)实施包括但不限于出租、出借、出售、散布、复制、修改、转载、汇编、发表、出版、还原工程、反向汇编、反向编译，或以其它方式发现原始码等的行为。
      </p>
      <p class="main">
        6.3.<b
          >本产品服务涉及的Logo、“书营AI写作”等文字图形及其组成，以及本产品其他标识、徽记、产品和服务名称均为本公司及其关联公司在中国和其它国家的商标，用户未经本公司书面授权不得以任何方式展示、使用或作其他处理，也不得向他人表明您有权展示、使用、或作其他处理。</b
        >
      </p>
      <p class="main">
        6.4.您理解并同意授权本公司在宣传和推广中使用您的名称、商标、标识，但仅限在宣传和推广中使用您的名称、商标、标识，但仅限于表明您属于我们的客户。
      </p>
      <div class="main_title">七、服务期限中止与终止</div>
      <p class="main">
        7.1.<b
          >本公司提供的书营AI写作产品及服务的有效期限自您开通服务之日起计算。</b
        >
      </p>
      <p class="main">
        7.2.<b
          >鉴于互联网服务的特殊性，本公司有权对服务内容进行变更，也可能会中断、中止或终止全部或者任一部分的服务，包括免费服务和收费服务，并在本产品官网上以公示的形式通知您。您了解并同意，本产品可能定期或不定期地对提供网络服务的平台设备、设施和软硬件进行维护或检修，如因此类情况而造成收费服务在合理时间内中止，本公司无需承担责任，但应事先在相关页面以公示的形式通告您。
        </b>
      </p>
      <p class="main">
        7.3.<b
          >如存在下列违约情形之一，本公司可立即对用户中止或终止服务，并要求用户赔偿损失：
        </b>
      </p>
      <div class="main">
        <li><b>用户违反第四条之注册义务；</b></li>
        <li><b>用户使用收费服务时未按规定支付相应服务费；</b></li>
        <li><b>用户违反本协议服务使用规范之规定。</b></li>
      </div>
      <p class="main_title">八、隐私政策</p>
      <p class="main">详见本公司的《书营AI写作隐私协议》</p>
      <p class="main_title">九、有限责任</p>
      <p class="main">9.1.服务将按“现状”和按“可得到”的状态提供。本公司在此明确声明对服务不作任何明示或暗示的保证，包括但不限于对服务的可适用性、没有错误或疏漏、持续性、准确性、可靠性、适用于某一特定用途之类的保证、声明或承诺。 </p>
      <p class="main">9.2.<b>本公司对服务所涉的技术和信息的有效性、准确性、正确性、可靠性、质量、稳定、完整和及时性均不作承诺和保证。 </b></p>
      <p class="main">9.3.不论在何种情况下，本公司均不对甶于internet连接故障、电脑、通讯或其他系统的故障、电力故障、罢工、劳动争议、暴乱、起义、骚乱、生产力或生产资料不足、火灾、洪水、风暴、爆炸、不可抗力、战争、政府行为、国际、国内法院的命令或第三方的不作为而造成的不能服务或延迟服务承担责任。 </p>
      <p class="main">9.4.不论是否可以预见，不论是源于何种形式的行为，本公司不对由以下原因造成的任何特别的、直接的、间接的、惩罚性的、突发性的或有因果关系的损害或其他任何损害(包括但不限于利润或利息的损失，营业中止，资料灭失)承担责任： </p>
      <div class="main">
        <li>使用或不能使用服务；</li>
        <li>通过服务购买或获取任何产品，样品，数据，信息或进行交易等，或其他可替代上述行为的行为而产生的费用； </li>
        <li>未经授权的存取或修改数据或数据的传输；</li>
        <li>第三方通过服务所作的陈述或行为；</li>
        <li>其它与服务相关事件，包括疏忽等，所造成的损害。</li>
      </div>
      <p class="main">9.5.<b>您充分了解并同意，鉴于互联网体制及环境的特殊性，您在服务中分享的信息及个人资料有可能会被他人复制、转载、擅改或做其它非法用途；您在此已充分意识此类风险的存在，并确认此等风险应完全由您自行承担，本公司对此不承担任何责任。</b></p>
      <p class="main">9.6.您了解并同意，在使用服务过程中可能存在来自任何他人的包括威胁性的、诽谤性的、令人反感的或非法的内容或行为或对他人权利的侵犯(包括知识产权)及匿名或冒名的信息的风险，基于服务使用规范所述，该等风险应由您自行承担，本公司对此不承担任何责任。 </p>
      <p class="main_title">十、违约责任及赔偿</p>
      <p class="main">10.1.本服务协议任何一方违约均须依法承担违约责任。</p>
      <p class="main">10.2.您理解，鉴于计算机、互联网的特殊性，下述情况不属于本公司违约：</p>
      <div class="main">
        <li>本公司在进行服务器配置、维护时，需要短时间中断服务；</li>
        <li>由于Internet上的通路阻塞造成您访问速度下降。</li>
      </div>
      <p class="main">10.3.在任何情况下，本公司均不对任何间接性、后果性、惩戒性、偶然性、特殊性的损害，包括您使用本公司服务而遭受的利润损失承担责任（即使您已被告知该等损失的可能性）。 </p>
      <p class="main">10.4.<b>如果本公司发现或收到他人举报您有违反本协议约定的，本公司有权不经通知随时对相关内容进行删除、屏蔽，并采取包括但不限于限制、暂停、终止您使用帐号及服务，限制、暂停、终止您使用部分或全部本服务，追究法律责任等措施。 </b></p>
      <p class="main">10.5.<b>您同意，由于您通过服务上载、传送或分享之信息、使用本服务其他功能、违反本协议、或您侵害他人任何权利因而衍生或导致任何第三人向本公司及其关联公司提出任何索赔或请求，或本公司及其关联公司因此而发生任何损失，您同意将足额进行赔偿(包括但不限于合理律师费)。 </b></p>
      <p class="main">10.6.<b>在任何情况下，本公司对本服务协议所承担的违约赔偿责任总额不超过违约服务对应之服务费总额。 </b></p>
      <p class="main_title">十一、争议解决及其他</p>
      <p class="main">11.1.本协议之解释与适用，以及与本协议有关的争议，均应依照中华人民共和国法律予以处理，<b>并以杭州市西湖区人民法院为第一审管辖法院。 </b></p>
      <p class="main">11.2.本协议之解释与适用，以及与本协议有关的争议，应尽量友好协商解决；协商不成时，则争议各方一致同意将发生的争议提交协商不成时，应依照中华人民共和国法律予以处理，任何一方均可向杭州市西湖区人民法院提起诉讼。 </p>
      <p class="main">11.3.本协议应取代双方此前就本协议任何事项达成的全部口头和书面协议、安排、谅解和通信。 </p>
      <p class="main">11.4.<b>您理解并同意，本公司有权自主决定经营策略并根据业务调整情况将本协议项下的全部权利义务并转移给其关联公司，转让将以本协议规定的方式通知，用户承诺对此不持有异议。 </b></p>
      <p class="main_title">十二、其他</p>
      <p class="main">如您对本协议或使用我们的服务相关的事宜有任何问题（包括问题咨询、投诉等），请通过功能页面的意见反馈入口与我们联系，我们会在收到您的意见及建议后尽快向您回复。 </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {};
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang='scss' scoped>
.agrement_acontent {
  max-width: 49.375rem;
  margin: 0 auto;
  box-shadow: 0 0 11px 0 rgba(63, 95, 150, 0.04);
  box-sizing: border-box;
  padding: 1.25rem;
  .title {
    width: 100%;
    height: 7.5rem;
    box-sizing: border-box;
    padding: 2.5rem;
    text-align: center;
    line-height: 2.5rem;
    text-align: center;
    font-size: 2.125rem;
    font-weight: 900;
    color: #1f2329;
    border-bottom: 2px #1f2329 solid;
  }
}

.container_precondition {
  margin: 1.25rem 0 0 0;
  .main {
    font-size: 0.875rem;
    margin: 0 0 1.25rem 0;
    text-align: left;
    line-height: 1.25rem;
  }
}

.container_main {
  margin: 1.25rem 0 0 0;
  .main_title {
    font-size: 1rem;
    font-weight: 700;
    text-align: left;
    margin: 0 0 1.25rem 0;
  }
  .main {
    font-size: 0.875rem;
    margin: 0 0 1.25rem 0;
    text-align: left;
    line-height: 1.25rem;
    li {
      list-style: square;
    }
    li:last-child {
      margin: 0 0 1.25rem;
    }
  }
}
</style>
